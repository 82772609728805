import React, { useState, useEffect } from 'react';
import { Select } from '@chakra-ui/react';
import { products } from '../api';
import useApi from '../hooks/useApi';

const ProductSelector = ({ onSelectionChange }) => {
  const { data: product, loading, error, execute } = useApi(products);
  const [selectedProducts, setSelectedProducts] = useState([]); // Array for multiple selected products
  const [selectedProductsId, setSelectedProductsId] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      await execute();
    };
    getProducts();
  }, [execute]);

  const handleDropdownChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);

    if (selectedOptions.includes("All")) {
      // Select all products if "All" is chosen
      const allProductIds = product.map(p => p.id);
      setSelectedProducts(["All"]);
      setSelectedProductsId(allProductIds);
      onSelectionChange(allProductIds); // Pass all product IDs to parent
    } else {
      // Select specific products (excluding "All")
      const selectedProductIds = product
        .filter(p => selectedOptions.includes(p.name))
        .map(p => p.id);
      setSelectedProducts(selectedOptions);
      setSelectedProductsId(selectedProductIds);
      onSelectionChange(selectedProductIds); // Pass selected product IDs to parent
    }
  };

  return (
    <div>
      <p>Please select a product</p>
      {loading ? (
        <p>Loading.....</p>
      ) : (
        <select
          multiple
          value={selectedProducts} // Controlled by selectedProducts (array)
          onChange={handleDropdownChange}
          variant="filled"
          bg="white"
          border="1px solid grey.300"
          fontSize={12}
          placeholder="Select a product"
          width="100%"
        >
          <option value="All">All</option>
          {product?.map(p => (
            <option key={p.id} value={p.name}>
              {p.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ProductSelector;
