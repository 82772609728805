import React from 'react';
import { GridItem, Box, Text, Flex} from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
const InformationCard = ({ title, value2, value, info, icon, target}) => {
  
  return (
    <GridItem>
      <Box bg="white" p={4} borderRadius="6px" position="relative" border="1px solid #CCDFF2">
        { icon ? <>{icon}</> :
        <Image src="/icons/chart-icon.svg"
         color="gray.500" width={50} position="absolute" top={"40%"} right={2} />}
        <Text
          fontFamily="Kumbh Sans"
          fontSize="12px"
          fontWeight={400}
          lineHeight="15px"
          textAlign="left"
        >
          {title}
        </Text>
        <Text
          fontFamily="Kumbh Sans"
          fontSize="22px"
          mt={4}
          fontWeight={500}
          lineHeight="15px"
          textAlign="left"
          color={value < target ? "red": "#1B2559"}
          display={'flex'}
        >
        {value ? value : 0}  {value2 ? <Text fontSize={10} color="#1B2559" ml={4}>({value2})</Text>: ""}   <Text fontSize={10} color="#1B2559" ml={4}>{target && `(${target})`}</Text><Flex color={'gray.400'} fontSize={11} ml={2}>{info}</Flex>
        </Text>
        
      
      </Box>
    </GridItem>
  );
};

export default InformationCard;
