import React from 'react';
import { Box, Button} from '@chakra-ui/react';

const ExportCabal= ({ dataObject }) => {
  const handleExportToExcel = () => {
    // Prepare data for export
    let rows = [];

    // Header row
    const headerRow = dataObject.allDates.map(date => {
      return date;
    });
    headerRow.unshift("ACCOUNT");
    headerRow.unshift("NAMES");
    rows.push(headerRow);

    // Data rows
    Object.keys(dataObject).forEach(accountNumber => {
      const rowData = [];

      if (!isNaN(Number(accountNumber))) {
        rowData.push(dataObject[accountNumber]?.transactions?.[0]?.ACCT_NM || ''); // Account Name
        rowData.push(accountNumber); // Account Number

        // Add transaction amounts for each date
        Object.values(dataObject.allDates).forEach(date => {
          rowData.push(dataObject[accountNumber]?.transactionsByDate?.[date]?.[0]?.TXN_AMT || 0);
        });

        rows.push(rowData);
      }
    });

    // Create CSV content
    const csvContent = rows.map(row => row.join(",")).join("\n");

    // Create Blob
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'transactions.csv');
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Cleanup
    document.body.removeChild(link);
  };

  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Button onClick={handleExportToExcel} mb={5}
      justifySelf={'flex-end'}
      bg={'darkblue'} color={'white'}
      fontSize={12}
      >
        Export Transactions
      </Button>
    </Box>
  );
};

export default ExportCabal;
